import React, { Fragment } from "react";
import styles from "../css/single-blog.module.css";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { Modal } from "@redq/reuse-modal";
import { agencyTheme } from "../theme/agency";
import { ResetCSS } from "../assets/css/style";
import {
  GlobalStyle,
  AgencyWrapper,
} from "../containers/reusableItem/agency.style";
import Navbar from "../containers/reusableItem/Navbar";
import Banner from "../containers/reusableItem/reusableBanner/background";
import BannerContent from "../containers/reusableItem/reusableBanner/bannerContent";
import Footer from "../containers/reusableItem/Footer";
import { DrawerProvider } from "../contexts/DrawerContext";
import "@redq/reuse-modal/es/index.css";
import SEO from "../components/seo";
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl";

const Blog = ({ data, pageContext }) => {
  const { contentNews, imageNews } = data.news;
  const options = {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: ({
        data: {
          target: { slug, title },
        },
      }) => <Link to={slug}>{title}</Link>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => <Img {...node.data.target} />,
    },
  };

  return (
    <ThemeProvider theme={agencyTheme}>
      <Fragment>
        <SEO title="Agency" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        {/* End of agency head section */}
        {/* Start agency wrapper section */}
        <AgencyWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Banner>
            <BannerContent title={<FormattedMessage id="indexPageNews" />} />
          </Banner>
          <section className={styles.blog}>
            <div className={styles.center}>
              <Img fluid={imageNews.fluid} alt="empty image" />
              <div className={styles.konten}>
                {/* <h1>{title}</h1> */}
                {/* <article className={styles.post}> */}
                {contentNews && renderRichText(contentNews, options)}
              </div>
              {/* </article> */}
            </div>
          </section>
          <Footer />
        </AgencyWrapper>
        {/* End of agency wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};

export const query = graphql`
  query getNews($slugNews: String!, $locale: String) {
    news: contentfulNews(
      slugNews: { eq: $slugNews }
      node_locale: { eq: $locale }
    ) {
      title: titleNews
      slug: slugNews
      node_locale
      contentNews {
        raw
      }
      imageNews {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`;

export default injectIntl(Blog);
